import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  DialogButton,
  Dropdown,
} from '@beeinventor/dasiot-react-component-lib';
import { CircularProgress, styled } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';

import { Locator, LocatorType } from '../../../types/Device';
import { SystemState } from '../../../types/Store';

import { getBleAoas, getDasbeacons, getUwbAoas } from '../../../apis/deviceApi';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setTransferDeviceMap } from '../../../slices/systemSlice';

import HardwareItem from '../../../components/HardwareItem';

import { locatorTypeList } from '../../../common/device';

const deviceFilterList: Array<{
  id: string;
  name: string;
  value: 'all' | LocatorType;
}> = [
  {
    id: 'all',
    name: 'All',
    value: 'all',
  },
  ...locatorTypeList,
];

const Container = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 48px)',
    '& > .filter-container': {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      background: '#c4c4c4',
      padding: '17px 48px',
      gap: '8px',
      '& > .info': {
        flex: '1 1 auto',
        fontWeight: 'bold',
      },
    },
    '& > .content': {
      flex: 1,
      padding: '46px 48px',
      overflow: 'auto',
      '& > .loading': {
        width: 'auto',
        height: 'auto',
      },
    },
    '& > .transfer-container': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: '0.875rem',
      lineHeight: 1.5,
      background: theme.color.secondary.$40,
      padding: '16px',
      borderRadius: '10px 10px 0px 0px',
      margin: '0 48px',
    },
  };
});

const LocatorPage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const buttomRef = useRef<HTMLDivElement | null>(null);
  const filterText = useAppSelector((store) => store.system.filter.text);
  const [deviceMap, setDasIdMap] = useState<SystemState['transferDeviceMap']>(
    {},
  );
  const [deviceType, setDeviceType] = useState<'all' | LocatorType>();
  const {
    data: getDasbeaconsResponse,
    fetchNextPage: dasbeaconFetchNextPage,
    hasNextPage: dasbeaconHasNextPage,
    isLoading: dasbeaconIsLoading,
    isFetchingNextPage: dasbeaconIsFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['get-dasbeacons', filterText],
    queryFn: async ({ pageParam }) => {
      return getDasbeacons({
        dasId: filterText !== '' ? filterText : undefined,
        nextCursor: pageParam,
      });
    },
    enabled: location.pathname === '/devices/locator',
    getNextPageParam: (res) => res.data.paging.nextCursor,
  });
  const {
    data: getBleAoasResponse,
    fetchNextPage: bleAoafetchNextPage,
    hasNextPage: bleAoaHasNextPage,
    isLoading: bleAoaIsLoading,
    isFetchingNextPage: bleAoaIsFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['get-ble-aoas', filterText],
    queryFn: async ({ pageParam }) => {
      return getBleAoas({
        dasId: filterText !== '' ? filterText : undefined,
        nextCursor: pageParam,
      });
    },
    enabled: location.pathname === '/devices/locator',
    getNextPageParam: (res) => res.data.paging.nextCursor,
  });
  const {
    data: getUwbAoasResponse,
    fetchNextPage: uwbAoafetchNextPage,
    hasNextPage: uwbAoaHasNextPage,
    isLoading: uwbAoaIsLoading,
    isFetchingNextPage: uwbAoaIsFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['get-uwb-aoas', filterText],
    queryFn: async ({ pageParam }) => {
      return getUwbAoas({
        dasId: filterText !== '' ? filterText : undefined,
        nextCursor: pageParam,
      });
    },
    enabled: location.pathname === '/devices/locator',
    getNextPageParam: (res) => res.data.paging.nextCursor,
  });

  useEffect(() => {
    let observer: IntersectionObserver;

    if (contentRef.current && buttomRef.current) {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (dasbeaconHasNextPage) {
                dasbeaconFetchNextPage();
              }
              if (bleAoaHasNextPage) {
                bleAoafetchNextPage();
              }
              if (uwbAoaHasNextPage) {
                uwbAoafetchNextPage;
              }
            }
          });
        },
        {
          root: contentRef.current,
          rootMargin: '48px',
        },
      );

      observer.observe(buttomRef.current);
    }

    return () => {
      if (contentRef.current && buttomRef.current) {
        observer?.unobserve(buttomRef.current);
      }
    };
  }, [
    dasbeaconHasNextPage,
    bleAoaHasNextPage,
    uwbAoaHasNextPage,
    dasbeaconFetchNextPage,
    bleAoafetchNextPage,
    uwbAoafetchNextPage,
  ]);

  const deviceItems = useMemo(() => {
    const dasbeacons = getDasbeaconsResponse?.pages
      .map((res) => res.data.data)
      .flat();
    const bleAoas = getBleAoasResponse?.pages
      .map((res) => res.data.data)
      .flat();
    const uwbAoas = getUwbAoasResponse?.pages
      .map((res) => res.data.data)
      .flat();

    let locators: Locator[] = [];

    if (
      dasbeacons &&
      (deviceType === undefined ||
        deviceType === 'all' ||
        deviceType === 'dasbeacon')
    ) {
      locators = locators.concat(dasbeacons);
    }

    if (
      bleAoas &&
      (deviceType === undefined ||
        deviceType === 'all' ||
        deviceType === 'ble_aoa')
    ) {
      locators = locators.concat(bleAoas);
    }

    if (
      uwbAoas &&
      (deviceType === undefined ||
        deviceType === 'all' ||
        deviceType === 'uwb_aoa')
    ) {
      locators = locators.concat(uwbAoas);
    }

    return locators
      .sort((a, b) => {
        const aUpdatedAt = new Date(a.updatedAt);
        const bUpdatedAt = new Date(b.updatedAt);
        return bUpdatedAt.getTime() - aUpdatedAt.getTime();
      })
      .map((locator, index) => {
        return (
          <HardwareItem
            key={`device-${locator.id}`}
            number={index + 1}
            data={locator}
            isSelected={deviceMap[locator.dasId]?.isSelected ?? false}
            onSelect={(data, value) => {
              setDasIdMap({
                ...deviceMap,
                [data.dasId]: {
                  id: data.id,
                  isSelected: value,
                },
              });
            }}
          />
        );
      });
  }, [
    deviceType,
    getDasbeaconsResponse,
    getBleAoasResponse,
    getUwbAoasResponse,
    deviceMap,
  ]);

  const selectedDevicesCount = Object.values(deviceMap).reduce((prev, curr) => {
    if (curr?.isSelected) {
      prev++;
    }
    return prev;
  }, 0);

  return (
    <Container>
      <div className="filter-container">
        <Dropdown
          sx={{
            display: 'inline-flex',
            maxWidth: '220px',
            paddingTop: 0,
            paddingBottom: 0,
          }}
          selectedId={deviceType}
          list={deviceFilterList}
          placeholder="Device Type"
          popperProps={{
            sx: {
              color: '#606060',
            },
          }}
          onSelect={(v) => {
            setDeviceType(() => {
              if (v === 'all') return undefined;
              return v as LocatorType;
            });
          }}
        />
        <div className="info">Total: {deviceItems.length}</div>
        <Button
          sx={{
            '& a': {
              color: '#fff',
              textDecoration: 'none',
            },
          }}
          variant="contained"
          color="primary"
          onClick={() => {
            //
          }}
        >
          <Link to="create">Add Locator</Link>
        </Button>
      </div>
      <div ref={contentRef} className="content">
        {dasbeaconIsLoading && bleAoaIsLoading && uwbAoaIsLoading ? (
          <div className="loading">
            <CircularProgress color="primary" />
          </div>
        ) : (
          deviceItems
        )}
        {dasbeaconIsFetchingNextPage &&
          bleAoaIsFetchingNextPage &&
          uwbAoaIsFetchingNextPage && (
            <div className="loading">
              <CircularProgress color="primary" />
            </div>
          )}
        <div ref={buttomRef} className="bottom" />
      </div>
      {selectedDevicesCount > 0 && (
        <div className="transfer-container">
          <span>
            {selectedDevicesCount}{' '}
            {selectedDevicesCount === 1 ? 'device' : 'devices'} selected
          </span>
          <DialogButton
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(setTransferDeviceMap(deviceMap));
              navigate('transfer-device');
            }}
          >
            Transfer
          </DialogButton>
        </div>
      )}
      <Outlet />
    </Container>
  );
};

export default LocatorPage;
