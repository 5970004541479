import { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Dropdown } from '@beeinventor/dasiot-react-component-lib';
import { styled, SxProps } from '@mui/material';

import { TaskStatus } from '../../types';

import TaskItem from './TaskItem';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 74px);
`;

const Header = styled('div')`
  display: flex;
  gap: 24px;
  padding: 17px 48px;
  background-color: ${({ theme }) => theme.color.secondary.$60};
`;

const ListContainer = styled('div')`
  flex: 1;
  padding: 17px 48px;
  overflow-y: auto;

  & a {
    text-decoration: none;
  }
`;

const dropdownSx: SxProps = {
  padding: 0,
  paddingLeft: '16px',
};

const popperPropsSx: SxProps = {
  color: '#606060',
  zIndex: 2000,
};

const TASK_TYPES = [
  {
    id: 'all',
    name: 'All',
    value: 'all',
  },
  {
    id: 'FU',
    name: 'Firmware Update',
    value: 'FU',
  },
  {
    id: 'AU',
    name: 'Audio Update',
    value: 'AU',
  },
  {
    id: 'PPA',
    name: 'Play PA',
    value: 'PPA',
  },
];

const TASK_STATUS: Array<{
  id: TaskStatus | 'all';
  name: string;
  value: TaskStatus | 'all';
}> = [
  {
    id: 'all',
    name: 'All',
    value: 'all',
  },
  {
    id: 'pending',
    name: 'Pending',
    value: 'pending',
  },
  {
    id: 'inProgress',
    name: 'In Progress',
    value: 'inProgress',
  },
  {
    id: 'canceled',
    name: 'Canceled',
    value: 'canceled',
  },
  {
    id: 'completed',
    name: 'Completed',
    value: 'completed',
  },
  {
    id: 'failed',
    name: 'Failed',
    value: 'failed',
  },
];

const FakeData: {
  id: string;
  dasId: string;
  status: 'pending' | 'inProgress' | 'canceled' | 'completed' | 'failed';
  taskType: 'Firmware Update' | 'Audio Update' | 'Play PA';
  createdAt: string;
}[] = [
  {
    id: 'FU004',
    dasId: 'LNBMTPE005747',
    status: 'pending',
    taskType: 'Firmware Update',
    createdAt: '2023-06-28T00:00:00+08:00',
  },
  {
    id: 'FU005',
    dasId: 'LNBMTPE005747',
    status: 'canceled',
    taskType: 'Firmware Update',
    createdAt: '2023-06-28T00:00:00+08:00',
  },
  {
    id: 'FU001',
    dasId: 'LNBMTPE005747',
    status: 'completed',
    taskType: 'Firmware Update',
    createdAt: '2023-06-28T00:00:00+08:00',
  },
  {
    id: 'AU002',
    dasId: 'LNBMTPE005740',
    status: 'failed',
    taskType: 'Audio Update',
    createdAt: '2023-06-28T00:13:00+08:00',
  },
  {
    id: 'PPA003',
    dasId: 'LNBMTPE005749',
    status: 'inProgress',
    taskType: 'Play PA',
    createdAt: '2023-06-28T00:15:23+08:00',
  },
];

const TaskListPage = () => {
  const [selectedTaskType, setSelectedTaskType] =
    useState<typeof TASK_TYPES[number]['id']>();
  const [selectedStatus, setSelectedStatus] =
    useState<typeof TASK_STATUS[number]['id']>();
  const items = FakeData.map((data, index) => {
    return (
      <Link key={`task-item-${data.id}`} to={`${data.id}/info`}>
        <TaskItem
          order={index + 1}
          status={data.status}
          dasId={data.dasId}
          taskType={data.taskType}
          createdAt={data.createdAt}
        />
      </Link>
    );
  });

  return (
    <>
      <Container>
        <Header>
          <Dropdown
            mode="light"
            sx={dropdownSx}
            popperProps={{
              sx: popperPropsSx,
            }}
            list={[
              {
                id: 'A001',
                name: 'A001',
                value: 'A001',
              },
              {
                id: 'A002',
                name: 'A002',
                value: 'A002',
              },
              {
                id: 'A003',
                name: 'A003',
                value: 'A003',
              },
            ]}
            onSelect={() => {
              //
            }}
            selectedId={undefined}
            placeholder="Project"
          />
          <Dropdown
            sx={dropdownSx}
            popperProps={{
              sx: popperPropsSx,
            }}
            list={TASK_TYPES}
            onSelect={(v) => {
              setSelectedTaskType(v as string);
            }}
            selectedId={selectedTaskType}
            placeholder="Task Type"
          />
          <Dropdown
            sx={dropdownSx}
            popperProps={{
              sx: popperPropsSx,
            }}
            list={TASK_STATUS}
            onSelect={(v) => {
              setSelectedStatus(v as TaskStatus);
            }}
            selectedId={selectedStatus}
            placeholder="Status"
          />
        </Header>
        <ListContainer>{items}</ListContainer>
      </Container>
      <Outlet />
    </>
  );
};

export default TaskListPage;
