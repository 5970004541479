import { ErrorState } from '../types';

class Validator {
  static validateEmail(email: string): ErrorState {
    if (email === '') {
      return {
        isError: true,
        message: 'Input is empty',
      };
    }

    if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email,
      )
    ) {
      return {
        isError: true,
        message: 'Not a email',
      };
    }

    return {
      isError: false,
      message: '',
    };
  }

  static validatePassword(password: string): ErrorState {
    if (password === '') {
      return {
        isError: true,
        message: 'Password is empty',
      };
    }
    if (password.length < 8) {
      return {
        isError: true,
        message: 'Password length at least 8',
      };
    }

    return {
      isError: false,
      message: '',
    };
  }

  static validateDasLoopDasId(dasId: string): ErrorState {
    if (dasId === '') {
      return {
        isError: true,
        message: 'Das ID is empty',
      };
    }

    if (dasId.length !== 13) {
      return {
        isError: true,
        message: 'Das ID should be 13 digits',
      };
    }

    if (!/L(N|L|W|F|M)(B|G|V)(C|E|J|M)[A-Z]{3}\d{6}/.test(dasId)) {
      return {
        isError: true,
        message: `Das ID "${dasId}" not match DasLoop naming rule`,
      };
    }

    return {
      isError: false,
      message: '',
    };
  }

  static validateDasTrackDasId(dasId: string): ErrorState {
    if (dasId === '') {
      return {
        isError: true,
        message: 'Das ID is empty',
      };
    }

    if (dasId.length !== 13) {
      return {
        isError: true,
        message: 'Das ID should be 13 digits',
      };
    }

    if (!/P(N|L|W|F|M)BA[A-Z]{3}\d{6}/.test(dasId)) {
      return {
        isError: true,
        message: `Das ID "${dasId}" not match DasTrack naming rule`,
      };
    }

    return {
      isError: false,
      message: '',
    };
  }

  static validateDasTrackVDasId(dasId: string): ErrorState {
    if (dasId === '') {
      return {
        isError: true,
        message: 'Das ID is empty',
      };
    }

    if (dasId.length !== 13) {
      return {
        isError: true,
        message: 'Das ID should be 13 digits',
      };
    }

    if (!/V(N|L|W|F|M)BA[A-Z]{3}\d{6}/.test(dasId)) {
      return {
        isError: true,
        message: `Das ID "${dasId}" not match DasTrack-V naming rule`,
      };
    }

    return {
      isError: false,
      message: '',
    };
  }

  static validateDasTempDasId(dasId: string): ErrorState {
    if (dasId === '') {
      return {
        isError: true,
        message: 'Das ID is empty',
      };
    }

    if (dasId.length !== 13) {
      return {
        isError: true,
        message: 'Das ID should be 13 digits',
      };
    }

    if (!/E(B|L|G)TA[A-Z]{3}\d{6}/.test(dasId)) {
      return {
        isError: true,
        message: `Das ID "${dasId}" not match DasTemp naming rule`,
      };
    }

    return {
      isError: false,
      message: '',
    };
  }

  static validateDasPowerDasId(dasId: string): ErrorState {
    if (dasId === '') {
      return {
        isError: true,
        message: 'Das ID is empty',
      };
    }

    if (dasId.length !== 13) {
      return {
        isError: true,
        message: 'Das ID should be 13 digits',
      };
    }

    if (!/E(B|L|G)PA[A-Z]{3}\d{6}/.test(dasId)) {
      return {
        isError: true,
        message: `Das ID "${dasId}" not match DasPower naming rule`,
      };
    }

    return {
      isError: false,
      message: '',
    };
  }

  static validateDasAirDasId(dasId: string): ErrorState {
    if (dasId === '') {
      return {
        isError: true,
        message: 'Das ID is empty',
      };
    }

    if (dasId.length !== 13) {
      return {
        isError: true,
        message: 'Das ID should be 13 digits',
      };
    }

    if (!/A(L|B)A[A-E][A-Z]{3}\d{6}/.test(dasId)) {
      return {
        isError: true,
        message: `Das ID "${dasId}" not match DasAir naming rule`,
      };
    }

    return {
      isError: false,
      message: '',
    };
  }

  static validateDasAoaTagDasId(dasId: string): ErrorState {
    if (dasId === '') {
      return {
        isError: true,
        message: 'Das ID is empty',
      };
    }

    if (dasId.length !== 13) {
      return {
        isError: true,
        message: 'Das ID should be 13 digits',
      };
    }

    if (!/T(B|U)(A|P)A[A-Z]{3}\d{6}/.test(dasId)) {
      return {
        isError: true,
        message: `Das ID "${dasId}" not match DasAoaTag naming rule`,
      };
    }

    return {
      isError: false,
      message: '',
    };
  }

  static validateDasWaterLDasId(dasId: string): ErrorState {
    if (dasId === '') {
      return {
        isError: true,
        message: 'Das ID is empty',
      };
    }

    if (dasId.length !== 13) {
      return {
        isError: true,
        message: 'Das ID should be 13 digits',
      };
    }

    if (!/WLLA[A-Z]{3}\d{6}/.test(dasId)) {
      return {
        isError: true,
        message: `Das ID "${dasId}" not match DasWater-L naming rule`,
      };
    }

    return {
      isError: false,
      message: '',
    };
  }

  static validateDasGasDasId(dasId: string): ErrorState {
    if (dasId === '') {
      return {
        isError: true,
        message: 'Das ID is empty',
      };
    }

    if (dasId.length !== 13) {
      return {
        isError: true,
        message: 'Das ID should be 13 digits',
      };
    }

    if (!/A(B|L)G[A-E][A-Z]{3}\d{6}/.test(dasId)) {
      return {
        isError: true,
        message: `Das ID "${dasId}" not match DasGas naming rule`,
      };
    }

    return {
      isError: false,
      message: '',
    };
  }

  static validateDasLockDasId(dasId: string): ErrorState {
    if (dasId === '') {
      return {
        isError: true,
        message: 'Das ID is empty',
      };
    }

    if (dasId.length !== 13) {
      return {
        isError: true,
        message: 'Das ID should be 13 digits',
      };
    }

    if (!/E(B|L|G|N)L(L|S)[A-Z]{3}\d{6}/.test(dasId)) {
      return {
        isError: true,
        message: `Das ID "${dasId}" not match DasLock naming rule`,
      };
    }

    return {
      isError: false,
      message: '',
    };
  }

  static validateDasCasGDasId(dasId: string): ErrorState {
    if (dasId === '') {
      return {
        isError: true,
        message: 'Das ID is empty',
      };
    }

    if (dasId.length !== 13) {
      return {
        isError: true,
        message: 'Das ID should be 13 digits',
      };
    }

    if (!/P(N|L|W|F|M)BG[A-Z]{3}\d{6}/.test(dasId)) {
      return {
        isError: true,
        message: `Das ID "${dasId}" not match Main Detector naming rule`,
      };
    }

    return {
      isError: false,
      message: '',
    };
  }

  static validateDasConcreteDasId(dasId: string): ErrorState {
    if (dasId === '') {
      return {
        isError: true,
        message: 'Das ID is empty',
      };
    }

    if (dasId.length !== 13) {
      return {
        isError: true,
        message: 'Das ID should be 13 digits',
      };
    }

    if (!/DLAA[A-Z]{3}\d{6}/.test(dasId)) {
      return {
        isError: true,
        message: `Das ID "${dasId}" not match DasConcrete naming rule`,
      };
    }

    return {
      isError: false,
      message: '',
    };
  }

  static validateAIServerDasId(dasId: string): ErrorState {
    if (dasId === '') {
      return {
        isError: true,
        message: 'Das ID is empty',
      };
    }

    if (dasId.length !== 13) {
      return {
        isError: true,
        message: 'Das ID should be 13 digits',
      };
    }

    if (!/IWAA[A-Z]{3}\d{6}/.test(dasId)) {
      return {
        isError: true,
        message: `Das ID "${dasId}" not match AI Server naming rule`,
      };
    }

    return {
      isError: false,
      message: '',
    };
  }

  static validateDasCasDasId(dasId: string): ErrorState {
    if (dasId === '') {
      return {
        isError: true,
        message: 'Das ID is empty',
      };
    }

    if (dasId.length !== 13) {
      return {
        isError: true,
        message: 'Das ID should be 13 digits',
      };
    }

    if (!/PNBC[A-Z]{3}\d{6}/.test(dasId)) {
      return {
        isError: true,
        message: `Das ID "${dasId}" not match DasCAS naming rule`,
      };
    }

    return {
      isError: false,
      message: '',
    };
  }
}

export default Validator;
