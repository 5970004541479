import { DasEndpointType, LocatorType } from '../types/Device';

export const deviceTypeList: Array<{
  id: string;
  name: string;
  value: DasEndpointType;
}> = [
  {
    id: 'dasloop',
    name: 'DasLoop',
    value: 'dasloop',
  },
  {
    id: 'dastrack',
    name: 'DasTrack',
    value: 'dastrack',
  },
  {
    id: 'dastemp',
    name: 'DasTemp',
    value: 'dastemp',
  },
  {
    id: 'daspower',
    name: 'DasPower',
    value: 'daspower',
  },
  {
    id: 'dasair',
    name: 'DasAir',
    value: 'dasair',
  },
  {
    id: 'das_aoa_tag',
    name: 'DasAoaTag',
    value: 'das_aoa_tag',
  },
  {
    id: 'dastrack_v',
    name: 'DasTrack-V',
    value: 'dastrack_v',
  },
  {
    id: 'daswater',
    name: 'DasWater-L',
    value: 'daswater',
  },
  {
    id: 'dasgas',
    name: 'DasGas',
    value: 'dasgas',
  },
  {
    id: 'daslock',
    name: 'DasLock',
    value: 'daslock',
  },
  {
    id: 'dascas',
    name: 'DasCAS',
    value: 'dascas',
  },
  {
    id: 'dascas_g',
    name: 'Main Detector',
    value: 'dascas_g',
  },
  {
    id: 'dasconcrete',
    name: 'DasConcrete',
    value: 'dasconcrete',
  },
  {
    id: 'das_ai_box',
    name: 'DasAiServer',
    value: 'das_ai_box',
  },
];

export const locatorTypeList: Array<{
  id: string;
  name: string;
  value: LocatorType;
}> = [
  {
    id: 'dasbeacon',
    name: 'DasBeacon',
    value: 'dasbeacon',
  },
  {
    id: 'ble_aoa',
    name: 'BLE_AOA',
    value: 'ble_aoa',
  },
  {
    id: 'uwb_aoa',
    name: 'UWB_AOA',
    value: 'uwb_aoa',
  },
];
