import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DialogButton,
  Dropdown,
} from '@beeinventor/dasiot-react-component-lib';
import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  styled,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ErrorState, MetaDataInputConfig } from '../../../../types';
import { DasEndpointType, LocatorType } from '../../../../types/Device';

import {
  checkDasEndpointExist,
  createDasEndpoint,
  getDasEndpoints,
} from '../../../../apis/dasEndpointApi';
import {
  registerBleAoa,
  registerDasbeacon,
  registerUwbAoa,
} from '../../../../apis/deviceApi';

import AddButton from '../../../../components/AddButton';
import Input from '../../../../components/Input';
import AddSvgIcon from '../../../../components/SvgIcon/AddSvgIcon';
import CrossSvgIcon from '../../../../components/SvgIcon/CrossSvgIcon';
import TrashSvgIcon from '../../../../components/SvgIcon/TrashSvgIcon';

import { deviceTypeList, locatorTypeList } from '../../../../common/device';
import theme, { inputSx } from '../../../../theme';
import { parseDasId } from '../../../../utils/common';
import ValidatorDasId from '../../../../utils/ValidatorDasId';
import ValidatorMetadata from '../../../../utils/ValidatorMetadata';

const Dialog = styled(MuiDialog)(({ theme }) => {
  return {
    '& .MuiPaper-root': {
      width: '520px',
      '& .header': {
        ...theme.typography.subtitle1,
        position: 'relative',
        textAlign: 'center',
        fontSize: '1.125rem',
        padding: '16px',
      },
    },
    '& .banner': {
      color: '#fff',
      fontSize: '1rem',
      padding: '16px',
      margin: '32px 0 16px 0',
      '&[data-status="error"]': {
        background: theme.color.highlight,
      },
      '&[data-status="success"]': {
        background: theme.color.green.$100,
      },
    },
  };
});

const DialogContent = styled(MuiDialogContent)(({ theme }) => {
  return {
    padding: '50px 60px 0 60px',
    '& > .property': {
      marginBottom: '16px',
      '& > label': {
        color: '#969696',
        '& > span': {
          color: theme.color.highlight,
        },
      },
    },
    '& button': {
      marginBottom: '8px',
    },
  };
});

const DialogActions = styled(MuiDialogActions)(() => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
  };
});

const CreateDevice = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dasId, setDasId] = useState(
    location.pathname === '/devices/das-endpoint/create' ? 'L' : 'B',
  );
  const [dasIdError, setDasIdError] = useState<ErrorState>({
    isError: false,
    message: '',
  });
  const [deviceType, setDeviceType] = useState<DasEndpointType | LocatorType>(
    location.pathname === '/devices/das-endpoint/create'
      ? 'dasloop'
      : 'dasbeacon',
  );
  const [responseSatusResult, setResponseSatusResult] = useState<{
    status: 'success' | 'error';
    message: string;
  }>({
    status: 'success',
    message: '',
  });

  const [metaDataConfig, setMetasDataConfig] = useState<{
    [key: string]: MetaDataInputConfig;
  }>(
    deviceType === 'dasloop'
      ? {
          uwbId: {
            isNumber: false,
            isRequired: false,
            isError: false,
            message: '',
            value: '',
          },
          sim: {
            isNumber: false,
            isRequired: false,
            isError: false,
            message: '',
            value: '',
          },
          collisionId: {
            isNumber: false,
            isRequired: true,
            isError: false,
            message: '',
            value: '',
          },
        }
      : {},
  );

  const createDasEndpointMutation = useMutation({
    mutationFn: createDasEndpoint,
    onMutate: () => {
      setResponseSatusResult({
        status: 'success',
        message: '',
      });
    },
    onSuccess: () => {
      setResponseSatusResult({
        status: 'success',
        message: 'Create dasendpoint success',
      });
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    },
    onError: (error: AxiosError<{ error: { message: string } }>) => {
      setResponseSatusResult({
        status: 'error',
        message: `
        Create dasendpoint failure. (Error: ${
          error.response?.data?.error?.message ?? error.message
        })
        `,
      });
    },
  });

  const createDasbeaconMutation = useMutation({
    mutationFn: registerDasbeacon,
    onMutate: () => {
      setResponseSatusResult({
        status: 'success',
        message: '',
      });
    },
    onSuccess: () => {
      setResponseSatusResult({
        status: 'success',
        message: 'Create locator success',
      });
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    },
    onError: (error: AxiosError<{ error: { message: string } }>) => {
      setResponseSatusResult({
        status: 'error',
        message: `
        Create locator failure. (Error: ${
          error.response?.data?.error?.message ?? error.message
        })
        `,
      });
    },
  });

  const createBleAoaMutation = useMutation({
    mutationFn: registerBleAoa,
    onMutate: () => {
      setResponseSatusResult({
        status: 'success',
        message: '',
      });
    },
    onSuccess: () => {
      setResponseSatusResult({
        status: 'success',
        message: 'Create locator success',
      });
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    },
    onError: (error: AxiosError<{ error: { message: string } }>) => {
      setResponseSatusResult({
        status: 'error',
        message: `
        Create locator failure. (Error: ${
          error.response?.data?.error?.message ?? error.message
        })
        `,
      });
    },
  });

  const createUwbAoaMutation = useMutation({
    mutationFn: registerUwbAoa,
    onMutate: () => {
      setResponseSatusResult({
        status: 'success',
        message: '',
      });
    },
    onSuccess: () => {
      setResponseSatusResult({
        status: 'success',
        message: 'Create locator success',
      });
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    },
    onError: (error: AxiosError<{ error: { message: string } }>) => {
      setResponseSatusResult({
        status: 'error',
        message: `
        Create locator failure. (Error: ${
          error.response?.data?.error?.message ?? error.message
        })
        `,
      });
    },
  });

  useEffect(() => {
    if (dasId.charAt(0) === 'T') {
      // Dasaoatag
      if (dasId.charAt(1) === 'U') {
        // uwb type
        setMetasDataConfig({
          ...metaDataConfig,
          uwbId: {
            value: metaDataConfig?.['uwbId'].value ?? '',
            isError: false,
            isNumber: false,
            isRequired: true,
            message: '',
          },
        });
      }
    } else if (deviceType === 'daslock') {
      if (dasId.charAt(2) === 'S') {
        setMetasDataConfig({
          ...metaDataConfig,
          gatewayId: {
            value: '',
            isError: false,
            isNumber: false,
            isRequired: true,
            message: '',
          },
        });
      } else if (dasId.charAt(2) === 'L') {
        setMetasDataConfig({
          ...metaDataConfig,
          phone: {
            value: '',
            isError: false,
            isNumber: false,
            isRequired: true,
            message: '',
          },
        });
      }
    }
  }, [dasId, deviceType]);

  const handleOnChangeDasId = (e: FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setDasId(e.currentTarget.value);
    const type = parseDasId(e.currentTarget.value);
    if (type === 'daslock' && deviceType === 'daslock') {
      setMetasDataConfig((prevData) => {
        if (newValue.charAt(3) === 'S') {
          const newItems: { [key: string]: MetaDataInputConfig } = {
            ...prevData,
            gatewayId: {
              value: '',
              isNumber: false,
              isRequired: true,
              isError: false,
              message: '',
            },
          };
          delete newItems.phone;
          return newItems;
        } else {
          const newItems: { [key: string]: MetaDataInputConfig } = {
            ...prevData,
            phone: {
              value: '',
              isNumber: false,
              isRequired: true,
              isError: false,
              message: '',
            },
          };
          delete newItems.gatewayId;
          return newItems;
        }
      });
    }
  };

  const handleOnBlurDasId = (e: FormEvent<HTMLInputElement>) => {
    setDasIdError(
      ValidatorDasId.validateDasId(e.currentTarget.value, deviceType),
    );
  };

  const handleOnBlurMetaData = (
    e: FormEvent<HTMLInputElement>,
    key: string,
  ) => {
    let value: any = e.currentTarget.value;
    let validation:
      | {
          isError: boolean;
          message: string;
        }
      | undefined;

    if (key === 'terminalId') {
      validation = ValidatorMetadata.validateTerminalId(value);
    } else if (key === 'reportInterval') {
      value = Number(value);
      validation = ValidatorMetadata.validateReportInterval(value);
    } else if (key === 'collisionId' || key === 'backupCollisionId') {
      validation = ValidatorMetadata.validateCollisionId(value);
    } else if (key === 'phone') {
      validation = ValidatorMetadata.validatePhone(value);
    } else if (key === 'gatewayId') {
      validation = ValidatorMetadata.validateGatewayId(value);
    } else if (key.indexOf('dascas_g_') !== -1) {
      validation = ValidatorDasId.validateDasId(
        e.currentTarget.value,
        'dascas_g',
      );
    } else if (key === 'aiBoxToken') {
      validation = ValidatorMetadata.validateRequired(value);
    } else if (key === 'aiBoxStreamHost') {
      validation = ValidatorMetadata.validateHttpUrl(value);
    } else if (key === 'aiBoxWorkHost') {
      validation = ValidatorMetadata.validateHttpUrl(value);
    }

    if (validation) {
      setMetasDataConfig((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          ...validation,
        },
      }));
    }
  };

  const handleOnSelectDasEndpointType = (v: string | number) => {
    setDeviceType(v as DasEndpointType | LocatorType);
    switch (v as DasEndpointType | LocatorType) {
      case 'dasloop':
        setDasId('L');
        setMetasDataConfig({
          sim: {
            isNumber: false,
            isRequired: false,
            isError: false,
            message: '',
            value: '',
          },
          uwbId: {
            isNumber: false,
            isRequired: false,
            isError: false,
            message: '',
            value: '',
          },
          collisionId: {
            isNumber: false,
            isRequired: false,
            isError: false,
            message: '',
            value: '',
          },
        });
        break;
      case 'dastrack':
        setDasId('P');
        setMetasDataConfig({
          uwbId: {
            isNumber: false,
            isRequired: false,
            isError: false,
            message: '',
            value: '',
          },
          sim: {
            isNumber: false,
            isRequired: false,
            isError: false,
            message: '',
            value: '',
          },
          collisionId: {
            isNumber: false,
            isRequired: false,
            isError: false,
            message: '',
            value: '',
          },
        });
        break;
      case 'dastemp':
        setDasId('EBTA');
        setMetasDataConfig({
          sn: {
            value: '',
            isNumber: false,
            isRequired: true,
            isError: false,
            message: '',
          },
          macAddress: {
            value: '',
            isNumber: false,
            isRequired: true,
            isError: false,
            message: '',
          },
        });
        break;
      case 'daspower':
        setDasId('EBPA');
        setMetasDataConfig({
          sn: {
            value: '',
            isNumber: false,
            isRequired: true,
            isError: false,
            message: '',
          },
          macAddress: {
            value: '',
            isNumber: false,
            isRequired: true,
            isError: false,
            message: '',
          },
          phase: {
            value: '',
            isNumber: true,
            isRequired: false,
            isError: false,
            message: '',
          },
          voltage: {
            value: '',
            isNumber: true,
            isRequired: false,
            isError: false,
            message: '',
          },
        });
        break;
      case 'das_aoa_tag':
        setDasId('T');
        setMetasDataConfig({});
        break;
      case 'dasair':
        setDasId('A');
        setMetasDataConfig({});
        break;
      case 'dastrack_v':
        setDasId('V');
        setMetasDataConfig({});
        break;
      case 'daswater':
        setDasId('WLLA');
        setMetasDataConfig({});
        break;
      case 'dasbeacon':
        setDasId('B');
        setMetasDataConfig({});
        break;
      case 'ble_aoa':
        setDasId('ALBA');
        setMetasDataConfig({});
        break;
      case 'uwb_aoa':
        setDasId('ALUA');
        setMetasDataConfig({});
        break;
      case 'dasgas':
        setDasId('A');
        setMetasDataConfig({
          serial: {
            value: '',
            isNumber: false,
            isRequired: true,
            isError: false,
            message: '',
          },
        });
        break;
      case 'daslock':
        setDasId('E');
        setMetasDataConfig({
          terminalId: {
            value: '',
            isNumber: false,
            isRequired: true,
            isError: false,
            message: '',
          },
        });
        break;
      case 'dascas':
        setDasId('P');
        setMetasDataConfig({
          uwbId: {
            isNumber: false,
            isRequired: false,
            isError: false,
            message: '',
            value: '',
          },
          collisionId: {
            title: 'Hock Tag',
            value: '',
            isNumber: false,
            isRequired: true,
            isError: false,
            message: '',
            placeholder: 'ID',
          },
          backupCollisionId: {
            title: 'Hock Tag',
            value: '',
            isNumber: false,
            isRequired: true,
            isError: false,
            message: '',
            placeholder: 'ID',
          },
          dascas_g_1: {
            title: 'Main Detector',
            value: '',
            isNumber: false,
            isRequired: true,
            isError: false,
            message: '',
            placeholder: 'Main Detector DasId',
          },
          dascas_g_2: {
            title: 'Main Detector',
            value: '',
            isNumber: false,
            isRequired: true,
            isError: false,
            message: '',
            placeholder: 'Main Detector DasId',
          },
          dascas_g_3: {
            title: 'Main Detector',
            value: '',
            isNumber: false,
            isRequired: true,
            isError: false,
            message: '',
            placeholder: 'Main Detector DasId',
          },
          dascas_g_4: {
            title: 'Main Detector',
            value: '',
            isNumber: false,
            isRequired: true,
            isError: false,
            message: '',
            placeholder: 'Main Detector DasId',
          },
        });
        break;
      case 'dascas_g':
        setDasId('P');
        setMetasDataConfig({
          sim: {
            value: '',
            isNumber: false,
            isRequired: true,
            isError: false,
            message: '',
          },
          uwbId: {
            value: '',
            isNumber: false,
            isRequired: true,
            isError: false,
            message: '',
          },
          collisionId: {
            value: '',
            isNumber: false,
            isRequired: false,
            isError: false,
            message: '',
          },
        });
        break;
      case 'dasconcrete':
        setDasId('D');
        setMetasDataConfig({});
        break;
      case 'das_ai_box':
        setDasId('I');
        setMetasDataConfig({
          aiBoxToken: {
            title: 'Token',
            value: '',
            isNumber: false,
            isRequired: true,
            isError: false,
            message: '',
          },
          aiBoxStreamHost: {
            title: 'Stream Host',
            value: '',
            isNumber: false,
            isRequired: true,
            isError: false,
            message: '',
            placeholder: 'https://...',
          },
          aiBoxWorkHost: {
            title: 'Server Host',
            value: '',
            isNumber: false,
            isRequired: true,
            isError: false,
            message: '',
            placeholder: 'https://...',
          },
        });
        break;
      default:
    }
  };

  const handleCreate = async () => {
    setResponseSatusResult({
      status: 'success',
      message: '',
    });
    if (location.pathname === '/devices/das-endpoint/create') {
      try {
        await checkDasEndpointExist(dasId);
        setResponseSatusResult({
          status: 'error',
          message: 'DasId is exist',
        });
      } catch (error) {
        let hasError = false;
        const localMetaData = Object.entries(metaDataConfig).reduce(
          (prev, [key, config]) => {
            prev[key] = config.value;
            return prev;
          },
          {} as {
            [key: string]: any;
          },
        );

        if (deviceType === 'dascas') {
          if (/^P[A-Z]{2}C/.test(dasId)) {
            const dascasGDasIds: string[] = [];
            Object.entries(localMetaData).forEach(([key, value]) => {
              if (key.indexOf('dascas_g_') !== -1) {
                delete localMetaData[key];
                dascasGDasIds.push(value as string);
              }
            });
            localMetaData.bindingDascasGs = [];
            for (let i = 0; i < dascasGDasIds.length; i++) {
              try {
                await checkDasEndpointExist(dascasGDasIds[i]);
                const dascasG = await getDasEndpoints({
                  dasId: dascasGDasIds[i],
                }).then((res) => res.data.data[0]);
                localMetaData.bindingDascasGs.push(dascasG.id);
              } catch (error) {
                if (error instanceof AxiosError) {
                  hasError = true;
                  setResponseSatusResult({
                    status: 'error',
                    message: `Main detector ${dascasGDasIds[i]} not exist`,
                  });
                  break;
                }
              }
            }
          }
        }

        if (deviceType === 'dasloop') {
          localMetaData.uwbId =
            localMetaData.uwbId === '' ? '-' : localMetaData.uwbId;
          localMetaData.sim =
            localMetaData.sim === '' ? '-' : localMetaData.sim;
        }

        if (!hasError) {
          createDasEndpointMutation.mutate({
            dasId,
            metadata: localMetaData,
          });
        }
      }
    } else if (deviceType === 'dasbeacon') {
      createDasbeaconMutation.mutate({
        dasId,
      });
    } else if (deviceType === 'ble_aoa') {
      createBleAoaMutation.mutate({
        dasId,
      });
    } else if (deviceType === 'uwb_aoa') {
      createUwbAoaMutation.mutate({
        dasId,
      });
    }
  };

  const metadataComponents = Object.entries(metaDataConfig).map(
    ([key, config]) => {
      return (
        <div key={`metadata-${key}`} className="property">
          <label>
            {config.title ?? key}
            {config.isRequired && <span>*</span>}
          </label>
          <Input
            sx={inputSx}
            placeholder={config.placeholder}
            value={String(config.value)}
            onChange={(e) => {
              setMetasDataConfig({
                ...metaDataConfig,
                [key]: {
                  ...metaDataConfig[key],
                  value: e.currentTarget.value ?? '',
                },
              });
            }}
            type={metaDataConfig[key]?.isNumber ? 'number' : 'text'}
            onBlur={(e) => handleOnBlurMetaData(e, key)}
            error={metaDataConfig[key]?.isError}
            errorMessage={metaDataConfig[key]?.message}
            min={metaDataConfig[key]?.min}
          />
        </div>
      );
    },
  );

  const isValid =
    dasId.length === 13 &&
    !dasIdError.isError &&
    Object.values(metaDataConfig).reduce((prev, curr) => {
      if (curr.isRequired && curr.value === '') return prev && false;
      return prev && !curr.isError;
    }, true);

  const maxDascasG = useMemo(() => {
    let max = 0;
    if (deviceType === 'dascas') {
      Object.keys(metaDataConfig).forEach((key) => {
        const regex = 'dascas_g_';
        if (key.indexOf(regex) !== -1) {
          const n = Number.parseInt(key.replace(regex, ''));
          if (max < n) {
            max = n;
          }
        }
      });
    }

    return max;
  }, [deviceType, metaDataConfig]);

  return (
    <Dialog open>
      <div className="header">
        {location.pathname === '/devices/das-endpoint/create'
          ? 'Create DasEndpoints'
          : 'Create Locator'}
        <CrossSvgIcon
          sx={{
            cursor: 'pointer',
            position: 'absolute',
            top: '8px',
            right: '8px',
            zIndex: 1,
            width: '40px',
            height: '40px',
          }}
          onClick={() => navigate(-1)}
        />
      </div>
      {responseSatusResult.message !== '' && (
        <div className="banner" data-status={responseSatusResult.status}>
          {responseSatusResult.message}
        </div>
      )}
      <DialogContent>
        <Dropdown
          sx={{
            display: 'inline-flex',
            width: '100%',
            paddingTop: 0,
            paddingBottom: 0,
            border: `1px solid ${theme.color.secondary.$60}`,
            marginBottom: '16px',
          }}
          selectedId={deviceType}
          list={
            location.pathname === '/devices/das-endpoint/create'
              ? deviceTypeList
              : locatorTypeList
          }
          placeholder="Device Type"
          popperProps={{
            sx: {
              color: '#606060',
              zIndex: 2000,
              maxHeight: '200px',
              overflow: 'auto',
            },
          }}
          onSelect={handleOnSelectDasEndpointType}
        />
        <div className="property">
          <label>
            {deviceType === 'dascas' ? 'Das ID(Cabin Alarm)' : 'Das ID'}
            <span>*</span>
          </label>
          <Input
            sx={inputSx}
            value={dasId}
            onChange={handleOnChangeDasId}
            onBlur={handleOnBlurDasId}
            error={dasIdError.isError}
            errorMessage={dasIdError.message}
          />
        </div>
        {metadataComponents}
        {deviceType === 'dascas' && (
          <AddButton
            onClick={() => {
              setMetasDataConfig({
                ...metaDataConfig,
                [`dascas_g_${maxDascasG + 1}`]: {
                  title: 'Main Detector',
                  value: '',
                  isNumber: false,
                  isRequired: true,
                  isError: false,
                  message: '',
                  placeholder: 'Main Detector DasId',
                },
              });
            }}
          >
            <AddSvgIcon color="primary" /> Add Main Detector
          </AddButton>
        )}
        {deviceType === 'dascas' && maxDascasG > 4 && (
          <AddButton
            onClick={() => {
              const newConfig = { ...metaDataConfig };
              delete newConfig[`dascas_g_${maxDascasG}`];
              setMetasDataConfig(newConfig);
            }}
          >
            <TrashSvgIcon color="primary" /> Remove Main Detector
          </AddButton>
        )}
      </DialogContent>
      <DialogActions>
        <DialogButton
          variant="text"
          color="primary"
          sx={(theme) => ({
            color: theme.color.primary.$100,
          })}
          onClick={() => navigate(-1)}
        >
          Cancel
        </DialogButton>
        <DialogButton
          variant="contained"
          color="primary"
          disabled={
            createDasEndpointMutation.isLoading ||
            createDasbeaconMutation.isLoading ||
            createBleAoaMutation.isLoading ||
            createUwbAoaMutation.isLoading ||
            !isValid
          }
          onClick={handleCreate}
        >
          Create
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDevice;
