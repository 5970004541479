import { useCallback, useEffect, useRef } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import { CircularProgress, styled } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';

import { RmrTicket } from '../../../types/RmrTicket';

import { getRmrTickets } from '../../../apis/rmrApi';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setHardwareFilter } from '../../../slices/systemSlice';

import AddSvgIcon from '../../../components/SvgIcon/AddSvgIcon';

import Header from './Header';
import SimpleRmrTicket from './SimpleRmrTicket';

const Container = styled('div')(() => {
  return {};
});

const Content = styled('div')(() => {
  return {
    height: 'calc(100vh - 74px - 74px)',

    '& > .add-container': {
      textAlign: 'center',
      padding: '24px',
    },

    '& > .list-container': {
      height: 'calc(100% - 80px)',
      padding: '0 48px',
      overflow: 'auto',
    },

    '.loading-container': {
      display: 'flex',
      justifyContent: 'center',
    },

    '.loader': {
      height: '20px',
    },
  };
});

const HardwarePage = () => {
  const location = useLocation();
  const listRef = useRef<HTMLDivElement | null>(null);
  const loader = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const filterText = useAppSelector((store) => store.system.filter.text);
  const { projectDateFilter, statusFilter, dueDate } = useAppSelector(
    (store) => store.system.filter.hardware,
  );
  const {
    data: rmrTickets,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ['get-rmrs', projectDateFilter, statusFilter, dueDate],
    queryFn: async ({ pageParam }) => {
      const res = await getRmrTickets({
        status: statusFilter === 'all' ? undefined : statusFilter,
        expiry:
          projectDateFilter === 'all'
            ? undefined
            : projectDateFilter === 'overdue',
        dueDate: dueDate ?? undefined,
        nextCursor: pageParam,
      });
      return res;
    },
    getNextPageParam: (lastPage) => {
      return lastPage.data.paging.nextCursor;
    },
    enabled: location.pathname === '/rmr/hardware',
    refetchOnWindowFocus: false,
  });

  const handleObserver = useCallback(
    (entries: any[]) => {
      const target = entries[0];
      if (target.isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    },
    [hasNextPage, fetchNextPage],
  );

  useEffect(() => {
    return () => {
      dispatch(
        setHardwareFilter({
          dueDate: null,
          projectDateFilter: 'all',
          statusFilter: 'all',
        }),
      );
    };
  }, []);

  useEffect(() => {
    const option = {
      root: listRef.current,
      rootMargin: '-5px',
      threshold: 0.5,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [handleObserver]);

  const handleOnClick = (ticketId: string) => {
    navigate(ticketId);
  };

  const simpleRmrTicketComponents = rmrTickets?.pages
    .map<RmrTicket[]>(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (res) => {
        return res.data.data;
      },
    )
    .flat()
    .filter((ticket) => {
      return (
        ticket.serialNumber.includes(filterText) ||
        ticket.customer.includes(filterText)
      );
    })
    .map((ticket, index) => (
      <SimpleRmrTicket
        key={`simple-rmr-ticket-${ticket.id}`}
        ticketNumber={index + 1}
        data={ticket}
        onClick={handleOnClick}
      />
    ));

  return (
    <>
      <Container>
        <Header />
        <Content className="content">
          <div className="add-container">
            <DialogButton
              sx={{
                fontWeight: '500',
                padding: '0 16px 0 32px',
              }}
              variant="contained"
              color="primary"
              endIcon={<AddSvgIcon sx={{ width: '32px', height: '32px' }} />}
              onClick={() => navigate('create')}
            >
              Add New Hardware RMR
            </DialogButton>
          </div>
          <div ref={listRef} className="list-container">
            {status === 'loading' ? (
              <div className="loading-container">
                <CircularProgress color="primary" />
              </div>
            ) : (
              <>
                {simpleRmrTicketComponents}
                {isFetching && isFetchingNextPage && (
                  <div className="loading-container">
                    <CircularProgress color="primary" />
                  </div>
                )}
                <div ref={loader} className="loader" />
              </>
            )}
          </div>
        </Content>
      </Container>
      <Outlet />
    </>
  );
};

export default HardwarePage;
